export const solNetwork = process.env.NEXT_PUBLIC_SOL_NETWORK || 'devnet';
export const isDevnet = solNetwork === 'devnet';

function getRandomNode(nodesString) {
  try {
    const nodes = JSON.parse(nodesString);
    return nodes[Math.floor(Math.random() * nodes.length)];
  } catch (err) {
    console.error('Failed to parse rpc nodes', err);
    return isDevnet ? 'https://api.devnet.solana.com' : 'https://api.mainnet-beta.solana.com';
  }
}

// Define RPC node strings explicitly so they don't get shaken out of the build
const publicNodesString = process.env.NEXT_PUBLIC_RPC_NODES;
export function getRandomPublicNode() {
  return getRandomNode(publicNodesString);
}

const privateNodesString = process.env.PRIVATE_RPC_NODES;
export function getRandomPrivateNode() {
  return getRandomNode(privateNodesString);
}
