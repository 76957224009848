'use client';

import { usePathname } from 'next/navigation';
import { addMilliseconds } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { PublicKey } from '@solana/web3.js';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { getAssociatedTokenAddress } from '@solana/spl-token';

import { GLOBAL_REFRESH_INTERVAL } from 'lib/constants';
import {
  allocationsCountState,
  currentTimeState,
  feesPoolState,
  needsRefreshState,
  nextRefreshState,
  phaseBidCountsState,
  prizePoolState,
  restrictionsState,
  selectedProjectDataState,
  selectedProjectBidsState,
  selectedProjectPhasesState,
  selectedProjectBidStatsState,
  tokensState,
  userSOLBalanceState,
  userBidsDataState,
  userPubkeyState,
  userRemainingBidsCountState,
  userTokenBalanceState,
  isUserPermittedState,
} from 'lib/state';

export default function AppDataProvider({ children }) {
  const pathname = usePathname();
  const { publicKey } = useWallet();
  const { connection } = useConnection();
  const [publicPhaseTimeout, setPPT] = useState();
  const [, setCurrentTime] = useRecoilState(currentTimeState);
  const [needsRefresh, setNeedsRefresh] = useRecoilState(needsRefreshState);
  const [nextRefreshIn, setNextRefresh] = useRecoilState(nextRefreshState);
  const [, setAllocationsCount] = useRecoilState(allocationsCountState);
  const [, setPhaseBidCounts] = useRecoilState(phaseBidCountsState);
  const [, setFeesPool] = useRecoilState(feesPoolState);
  const [, setPrizePool] = useRecoilState(prizePoolState);
  const [, setProjectBidsProgress] = useRecoilState(selectedProjectBidsState);
  const [, setProjectBidStats] = useRecoilState(selectedProjectBidStatsState);
  const [projectData, setProjectData] = useRecoilState(selectedProjectDataState);
  const [phaseData, setPhaseData] = useRecoilState(selectedProjectPhasesState);
  const [, setRestrictions] = useRecoilState(restrictionsState);
  const [, setTokens] = useRecoilState(tokensState);
  const [, setUserSOLBalance] = useRecoilState(userSOLBalanceState);
  const [, setUserTokenBalance] = useRecoilState(userTokenBalanceState);
  const [, setUserBidsData] = useRecoilState(userBidsDataState);
  const [, setUserRemainingBidsCount] = useRecoilState(userRemainingBidsCountState);
  const [, setIsUserPermitted] = useRecoilState(isUserPermittedState);
  const [userPubkey, setUserPubkey] = useRecoilState(userPubkeyState);

  const [, one, two, three] = useMemo(() => pathname?.split('/') || [], [pathname, needsRefresh]); // eslint-disable-line

  const fetchProjectData = useCallback(
    async (pid) => {
      if (!setProjectData) {
        return;
      }
      fetch(`/api/projects/${pid}`)
        .then((p) => p?.json())
        .then(setProjectData);
    },
    [setProjectData]
  );

  const fetchProjectPhases = useCallback(
    async (pid) => {
      if (!setPhaseData) {
        return;
      }
      fetch(`/api/projects/${pid}/phases`)
        .then((p) => p?.json())
        .then(setPhaseData);
    },
    [setPhaseData]
  );

  const fetchPrizePool = useCallback(
    (pid) => {
      if (!setPrizePool) {
        return;
      }
      fetch(`/api/projects/${pid}/pot`)
        .then((p) => p?.json())
        .then(setPrizePool);
    },
    [setPrizePool]
  );

  const fetchFeesPool = useCallback(
    (pid) => {
      if (!setFeesPool) {
        return;
      }
      fetch(`/api/projects/${pid}/fees`)
        .then((p) => p?.json())
        .then(setFeesPool);
    },
    [setFeesPool]
  );

  const fetchUserBids = useCallback(
    (pubkey) => {
      if (!setUserBidsData) {
        return;
      }
      if (!pubkey) {
        setUserBidsData(null);
        return;
      }
      fetch(`/api/bids/user/${pubkey}`)
        .then((p) => p?.json())
        .then(setUserBidsData);
    },
    [setUserBidsData]
  );

  const fetchProjectBids = useCallback(
    (pid) => {
      fetch(`/api/bids/project/${pid}`)
        .then((x) => x?.json())
        .then(setProjectBidsProgress);
    },
    [setProjectBidsProgress]
  );

  const fetchProjectBidStats = useCallback(
    (pid) => {
      fetch(`/api/projects/${pid}/stats`)
        .then((x) => x?.json())
        .then(setProjectBidStats);
    },
    [setProjectBidStats]
  );

  const fetchPhaseBids = useCallback(
    (pid) => {
      fetch(`/api/phases/bids/${pid}`)
        .then((x) => x?.json())
        .then(setPhaseBidCounts);
    },
    [setPhaseBidCounts]
  );

  const fetchIsPermitted = useCallback(
    (pid) => {
      if (!publicKey) {
        return false;
      }
      fetch(`/api/projects/${pid}/permitted`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ publicKey: publicKey?.toBase58() }),
      })
        .then((x) => x?.json())
        .then(({ isPermitted }) => setIsUserPermitted(isPermitted));
    },
    [publicKey, setIsUserPermitted]
  );

  const fetchRemainingBids = useCallback(
    (pid) => {
      if (!publicKey) {
        return false;
      }
      fetch(`/api/projects/${pid}/permitted/count`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ publicKey: publicKey?.toBase58() }),
      })
        .then((x) => x?.json())
        .then(({ remaining }) => setUserRemainingBidsCount(remaining));
    },
    [publicKey, setUserRemainingBidsCount]
  );

  useEffect(() => {
    if (one === 'projects' && two) {
      fetchIsPermitted(two);
    }
    if (!two) {
      setIsUserPermitted(false);
    }
  }, [fetchIsPermitted, setIsUserPermitted, one, two, needsRefresh]);

  const updateNextRefresh = useCallback(() => {
    const newTime = new Date().getTime();
    setCurrentTime(newTime);
    if (newTime < nextRefreshIn) {
      return;
    }
    setNextRefresh(addMilliseconds(new Date(), GLOBAL_REFRESH_INTERVAL).getTime());
    setNeedsRefresh(newTime);
  }, [nextRefreshIn, setNextRefresh, setNeedsRefresh, setCurrentTime]);

  const fetchTokenData = useCallback(() => {
    fetch('/api/tokens')
      .then((x) => x?.json())
      .then(setTokens);
  }, [setTokens]);

  const fetchRestrictions = useCallback(() => {
    fetch('/api/admin/restrictions')
      .then((x) => x?.json())
      .then(setRestrictions);
  }, [setRestrictions]);

  const fetchAllocationsCount = useCallback(
    async (rawId) => {
      const cleanId = rawId.split('-').pop();
      const res = await fetch(`/api/allocations/${cleanId}/count`);
      const data = await res.json();
      setAllocationsCount(data);
    },
    [setAllocationsCount]
  );

  // Fetch data based on state you visit
  useEffect(() => {
    if (one === 'projects' && two) {
      fetchProjectData(two);
      fetchProjectBids(two);
      fetchProjectBidStats(two);
      fetchPhaseBids(two);
      fetchRemainingBids(two);
      fetchProjectPhases(two);
      fetchPrizePool(two);
      fetchFeesPool(two);
    }
    if (one === 'a' && two) {
      fetchAllocationsCount(two);
    }
    if (one === 'admin' && two === 'projects' && three) {
      fetchProjectData(three);
    }
    if (one === 'admin') {
      fetchRestrictions();
    }
    if (!two) {
      setProjectBidStats(null);
      setProjectBidsProgress(null);
      setProjectData(null);
      setPhaseData(null);
      fetchPhaseBids(null);
      fetchRemainingBids(null);
      setIsUserPermitted(false);
    }
    fetchTokenData();
  }, [
    one,
    two,
    three,
    fetchAllocationsCount,
    fetchIsPermitted,
    fetchPhaseBids,
    fetchPrizePool,
    fetchFeesPool,
    fetchProjectData,
    fetchProjectBidStats,
    fetchProjectBids,
    fetchProjectPhases,
    fetchRemainingBids,
    fetchRestrictions,
    fetchTokenData,
    setIsUserPermitted,
    setPhaseData,
    setProjectBidStats,
    setProjectBidsProgress,
    setProjectData,
    needsRefresh,
  ]);

  const updateTokenBalance = useCallback(
    async (tokenBase58) => {
      try {
        const tokenPubkey = new PublicKey(tokenBase58);
        const tokenAddress = await getAssociatedTokenAddress(tokenPubkey, publicKey);
        const account = await connection.getParsedAccountInfo(tokenAddress);
        const { amount } = account?.value?.data?.parsed?.info?.tokenAmount;
        setUserTokenBalance(amount);
      } catch (err) {
        console.error('Failed to get token balance', err);
      }
    },
    [connection, setUserTokenBalance, publicKey]
  );

  useEffect(() => {
    setUserPubkey(publicKey);
    if (!publicKey) {
      setUserSOLBalance(0);
      setUserTokenBalance(0);
      return;
    }
    connection?.getBalance(publicKey).then(setUserSOLBalance);
    if (projectData?.token_account && projectData.token_account !== 'SOL') {
      updateTokenBalance(projectData.token_account);
    }
  }, [
    connection,
    projectData,
    publicKey,
    setUserPubkey,
    needsRefresh,
    setUserSOLBalance,
    setUserTokenBalance,
    updateTokenBalance,
  ]);

  useEffect(() => {
    if (!(phaseData && phaseData[0]?.start_time)) {
      return;
    }
    const [firstPhase] = phaseData;
    const timeoutDuration = new Date(firstPhase.start_time).getTime() - new Date().getTime();
    if (timeoutDuration < 1) {
      return;
    }
    if (publicPhaseTimeout) {
      clearTimeout(publicPhaseTimeout);
    }
    setPPT(setTimeout(() => setNeedsRefresh(new Date().getTime()), timeoutDuration));
  }, [phaseData, publicPhaseTimeout, setNeedsRefresh]);

  useEffect(() => {
    fetchUserBids(userPubkey);
  }, [userPubkey, fetchUserBids, needsRefresh]);

  // Global refresh countdown timer
  useEffect(() => {
    const intervalId = setInterval(updateNextRefresh, 1000);
    return () => clearInterval(intervalId);
  }, [updateNextRefresh]);

  return children;
}
