import { addMilliseconds } from 'date-fns';
import { atom, selector } from 'recoil';
import { GLOBAL_REFRESH_INTERVAL } from 'lib/constants';

// Expects to be a string representing user's pubkey
export const userPubkeyState = atom({
  key: 'userPubkeyState',
  default: null,
});

export const isUserPermittedState = atom({
  key: 'isUserPermittedState',
  default: false,
});

// Updated with a new Date().getTime() to force data refreshes
// Monitored in components/AppDataProvider
export const needsRefreshState = atom({
  key: 'needsRefreshState',
  default: new Date().getTime(),
});

export const currentTimeState = atom({
  key: 'currentTimeState',
  default: new Date().getTime(),
});

// Set the default next refresh to happen 1 minute from now
export const nextRefreshState = atom({
  key: 'nextRefreshState',
  default: addMilliseconds(new Date(), GLOBAL_REFRESH_INTERVAL).getTime(),
});

export const selectedProjectDataState = atom({
  key: 'selectedProjectDataState',
  default: null,
});

export const selectedProjectPresalesState = atom({
  key: 'selectedProjectPresalesState',
  default: null,
});

export const selectedProjectBidsState = atom({
  key: 'selectedProjectBidsState',
  default: null,
});

export const selectedProjectPhasesState = atom({
  key: 'selectedProjectPhasesState',
  default: null,
});

export const selectedProjectBidStatsState = atom({
  key: 'selectedProjectBidStatsState',
  default: null,
});

export const transactionsToProcessState = atom({
  key: 'transactionsToProcessState',
  default: null,
});

export const userSOLBalanceState = atom({
  key: 'userSOLBalanceState',
  default: null,
});

export const userTokenBalanceState = atom({
  key: 'userTokenBalanceState',
  default: null,
});

export const userBidsDataState = atom({
  key: 'userBidsDataState',
  default: null,
});

export const userRemainingBidsCountState = atom({
  key: 'userRemainingBidsCountState',
  default: null,
});

// Used for knowing when to clear the bidding summary
export const lastSuccessfulSubmitState = atom({
  key: 'lastSuccessfulSubmitState',
  default: null,
});

//////////////////
// Derived Data //
//////////////////

export const userActiveBidsState = selector({
  key: 'userActiveBidsState',
  get: ({ get }) => {
    const bids = get(userBidsDataState);
    const activeBids = bids?.filter((b) => ['active', 'refunding'].includes(b.status)) || [];
    return activeBids.reduce((acc, { project_id, ...rest }) => {
      if (!acc[project_id]) {
        acc[project_id] = [];
      }
      acc[project_id].push(rest);
      return acc;
    }, {});
  },
});

export const userRefundedBidsState = selector({
  key: 'userRefundedBidsState',
  get: ({ get }) => {
    const bids = get(userBidsDataState);
    const refundedBids = bids?.filter((b) => b.status === 'outbid') || [];
    return refundedBids.reduce((acc, { project_id, ...rest }) => {
      if (!acc[project_id]) {
        acc[project_id] = [];
      }
      acc[project_id].push(rest);
      return acc;
    }, {});
  },
});

export const userBidsByProjectState = selector({
  key: 'userBidsByProjectState',
  get: ({ get }) => {
    const bids = get(userBidsDataState) || [];
    return bids.reduce((acc, { project_id, ...rest }) => {
      if (!acc[project_id]) {
        acc[project_id] = [];
      }
      acc[project_id].push(rest);
      return acc;
    }, {});
  },
});

export const isProjectCompleteState = selector({
  key: 'isProjectCompleteState',
  get: ({ get }) => {
    const phases = get(selectedProjectPhasesState);
    if (!phases || phases.length < 1) {
      return false;
    }
    return phases.every((phase) => new Date(phase.end_time).getTime() < new Date().getTime());
  },
});

export const currentPhaseState = selector({
  key: 'currentPhaseState',
  get: ({ get }) => {
    const phases = get(selectedProjectPhasesState);
    const currentTime = get(currentTimeState);
    return phases?.find(
      (phase) =>
        new Date(phase.start_time).getTime() <= currentTime &&
        new Date(phase.end_time).getTime() > currentTime
    );
  },
});

export const isWLPhaseState = selector({
  key: 'isWLPhaseState',
  get: ({ get }) => {
    const currentPhase = get(currentPhaseState);
    return currentPhase?.phase > 2; // 1 is public mint, 2 is power hour; see migrations/000-db.js
  },
});

export const wlSupplyState = selector({
  key: 'wlSupplyState',
  get: ({ get }) => {
    const phases = get(selectedProjectPhasesState);
    if (!(phases && phases?.filter)) {
      return null;
    }
    const wlPhases = (phases || []).filter((p) => p.phase > 1);
    return wlPhases.reduce((acc, { supply }) => acc + Number(supply), 0);
  },
});

export const phaseBidCountsState = atom({
  key: 'phaseBidCountsState',
  default: {},
});

export const preallocatedSupplyState = selector({
  key: 'preallocatedSupplyState',
  get: ({ get }) => {
    const project = get(selectedProjectDataState);
    if (!project?.team_supply) {
      return null;
    }
    return Number(project.team_supply);
  },
});

export const restrictionsState = atom({
  key: 'restrictionsState',
  default: [],
});

export const tokensState = atom({
  key: 'tokensState',
  default: [],
});

export const tokensByIdState = selector({
  key: 'tokensByIdState',
  get: ({ get }) => {
    const tokens = get(tokensState);
    return (tokens || []).reduce((acc, { id, ...rest }) => {
      acc[id] = { id, ...rest };
      return acc;
    }, {});
  },
});

export const feesPoolState = atom({
  key: 'feesPoolState',
  default: null,
});

export const prizePoolState = atom({
  key: 'prizePoolState',
  default: null,
});

export const allocationsCountState = atom({
  key: 'allocationsCountState',
  default: null,
});
